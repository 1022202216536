<template>
    <v-container>
        <v-btn elevation="2" fab absolute small @click="$router.go(-1)" v-show="!$vuetify.breakpoint.smAndDown"><v-icon
        color="primary">mdi-arrow-left-bold</v-icon></v-btn>

        <v-row dense>
            <v-col class="text-h3" align="center">
                Novinky
            </v-col>
        </v-row>
        <v-row dense>
            <v-col class="text-h5" align="center">
                Čo je nové?
            </v-col>
        </v-row>

        <v-row justify="space-between" align="center" no-gutters>
            <v-col cols="12" md="6" align="left" align-self="left">
                <v-text-field v-model="search" label="Vyhľadaj" clearable></v-text-field>
            </v-col>

            <v-col cols="12" sm="3" md="3" lg="2" align="right">
                <v-btn v-if="filter_favorite" text color="amber amber-darken-4" @click="filter_favorite = false">
                    Iba obľúbené &nbsp;<v-icon>mdi-star</v-icon>
                </v-btn>

                <v-btn v-else text color="amber amber-darken-4" @click="filter_favorite = true">
                    Iba obľúbené &nbsp;<v-icon>mdi-star-outline</v-icon>
                </v-btn>

            </v-col>
        </v-row>

        <v-divider></v-divider>

        <v-row>
            <v-col cols="12" align="left" align-self="left">
                <v-list rounded>
                    <v-list-item-group color="primary">
                        <v-row v-for="item in items" v-bind:key="item.id" align="center">
                            <v-col cols="11">

                                <v-list-item :to="getLink(item)">
                                    <template v-slot:default="{}">
                                        <v-list-item-content v-if="item.name" tag="span" class="primary--text text-button">
                                            {{ item.name }}
                                        </v-list-item-content>

                                        <v-list-item-content v-else tag="span" class="primary--text text-button">
                                            {{ item.first_field }}
                                        </v-list-item-content>


                                        <span v-if="item.not_voted_questions_count"
                                            class="red--text lighten-3--text">({{ item.not_voted_questions_count
                                            }})</span>
                                    </template>
                                </v-list-item>
                            </v-col>
                            <v-col cols="1" align="right" align-self="left">
                                <v-btn v-if="item.user_favorite" icon color="amber amber-darken-4"
                                    @click="removeFavorite(item)"><v-icon>mdi-star
                                    </v-icon>
                                </v-btn>
                                <v-btn v-else icon color="amber amber-darken-4"
                                    @click="addFavorite(item)"><v-icon>mdi-star-outline
                                    </v-icon>
                                </v-btn>

                            </v-col>
                        </v-row>
                    </v-list-item-group>
                </v-list>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
import { mapGetters } from "vuex";

import { APIService } from "@/http/APIService.js";

const apiService = new APIService();


export default {
    name: 'News',
    inheritAttrs: true,

    components: {
    },

    props: {
    },

    data() {
        return {
            categories: [],
            polls: [],
            pollQuestions: [],

            filter_favorite: false,
            search: "",

            timeout: undefined
        }
    },

    computed: {
        ...mapGetters("auth", [
            "isAuthenticated",
            "username",
            "isVerified",
            "isVerifiedBank",
            "isVerifiedSmsEmail",
            "isStaff",
            "getMemberStatus",
            "firstName",
            "lastName",
            "gender",
            "user_id"
        ]),

        ...mapGetters("settings", ["getSettings"]),

        items() {

            let categoriesTmp = this.categories.map(obj => ({ ...obj, type: 'category' }))
            let pollsTmp = this.polls.map(obj => ({ ...obj, type: 'poll' }))
            let pollQuestionsTmp = this.pollQuestions.map(obj => ({ ...obj, type: 'pollQuestion' }))

            let tmp = categoriesTmp.concat(pollsTmp, pollQuestionsTmp);
            tmp.sort((a, b) => a.news_order - b.news_order)

            return tmp;
        }
    },


    events: {},

    watch: {
        filter_favorite() {
            this.getItems();
        },

        search() {
            if (this.timeout) {
                clearTimeout(this.timeout);
            }

            this.timeout = setTimeout(() => {
                this.getItems();
            }, 500);
        },
    },

    mounted: function () {
        this.getItems();
    },

    methods: {
        getLink(item) {
            if (item.url)
                return item.url;

            switch (item.type) {
                case 'category':
                    return '/poll_category/' + item.id;
                case 'poll':
                    return '/poll_category/' + item.poll_category + '/poll/' + item.id + '/';
                case 'pollQuestion':
                    return '/poll_category/' + item.poll_category + '/poll/' + item.poll + '/#' + item.id;
                default:
                    return "";
            }
        },

        removeFavorite(item) {
            switch (item.type) {
                case 'category':
                    apiService.generalPollCategoryRemoveFavorite(item.id).then(() => {
                        this.getItems();
                    });
                    break;
                case 'poll':
                    apiService.generalPollRemoveFavorite(item.id).then(() => {
                        this.getItems();
                    });
                    break;
                case 'pollQuestion':
                    apiService.generalPollQuestionRemoveFavorite(item.id).then(() => {
                        this.getItems();
                    });
                    break;
                default:
                    this.getItems();
            }
        },

        addFavorite(item) {
            switch (item.type) {
                case 'category':
                    apiService.generalPollCategoryAddFavorite(item.id).then(() => {
                        this.getItems();
                    });
                    break;
                case 'poll':
                    apiService.generalPollAddFavorite(item.id).then(() => {
                        this.getItems();
                    });
                    break;
                case 'pollQuestion':
                    apiService.generalPollQuestionAddFavorite(item.id).then(() => {
                        this.getItems();
                    });
                    break;
                default:
                    this.getItems();
            }
        },

        getItems() {
            let limit = 0;
            let offset = 0;
            let filters = [];

            if (this.filter_favorite)
                filters.push(['favorite', 1]);

            filters.push(['news_include', 1]);
            filters.push(['news_date_to', new Date().toISOString()]);

            apiService.getGeneralPollCategories(this.sort, this.search, limit, offset, filters).then((response) => {
                this.categories = response.results;
            });

            apiService.getGeneralPolls(this.sort, this.search, limit, offset, filters).then((response) => {
                this.polls = response.results;
            });

            apiService.getGeneralPollQuestions(this.sort, this.search, limit, offset, filters).then((response) => {
                this.pollQuestions = response.results;
            });
        },
    },
};
</script>